<template>
  <div>
    <div class="networks row">
      <div
        class="col-6 col-md-4 col-lg-3 mb-3"
        v-for="network in networks"
        :key="network.iso"
      >
        <router-link
          :to="{
            name: 'Network',
            params: {
              code: network.ProviderCode.toLowerCase(),
              name: network.Name.replace(/\s/g, '-').toLowerCase(),
              iso: network.CountryIso.toLowerCase(),
              country: country.CountryName.replace(/\s/g, '-').toLowerCase(),
              logo: network.LogoUrl
            }
          }"
          class="card overflow-hidden h-100"
          >
          <div class="card-img img-thumbnail border-0 text-center py-4">
            <img
              :src="network.LogoUrl.replace('png', 'svg')"
              :onerror="'this.src=' + network.LogoUrl"
              height="30"
              class="mw-100 w-75"
            />
          </div>
          <div class="card-footer d-flex align-items-center flex-grow-1 p-2 small">
            <b class="text-muted">{{ network.Name }}</b>
          </div>
        </router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      networks: []
    };
  },
  methods: {
    fetchNetworks() {
      this.loading = true;
      this.$http({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}networks?countryIsos=${this.country.CountryIso}`
      }).then(
        result => {
          this.networks = result.data.networks;
          this.loading = false;
        },
        error => {
          console.error(error);
        } 
      );
    }
  },
  watch: {
    country() {
      this.fetchNetworks();
    }
  },
  computed: {
    ...mapGetters({
      country: "country"
    })
  },
  created() {
    this.fetchNetworks();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
