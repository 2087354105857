var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"networks row"},_vm._l((_vm.networks),function(network){return _c('div',{key:network.iso,staticClass:"col-6 col-md-4 col-lg-3 mb-3"},[_c('router-link',{staticClass:"card overflow-hidden h-100",attrs:{"to":{
          name: 'Network',
          params: {
            code: network.ProviderCode.toLowerCase(),
            name: network.Name.replace(/\s/g, '-').toLowerCase(),
            iso: network.CountryIso.toLowerCase(),
            country: _vm.country.CountryName.replace(/\s/g, '-').toLowerCase(),
            logo: network.LogoUrl
          }
        }}},[_c('div',{staticClass:"card-img img-thumbnail border-0 text-center py-4"},[_c('img',{staticClass:"mw-100 w-75",attrs:{"src":network.LogoUrl.replace('png', 'svg'),"onerror":'this.src=' + network.LogoUrl,"height":"30"}})]),_c('div',{staticClass:"card-footer d-flex align-items-center flex-grow-1 p-2 small"},[_c('b',{staticClass:"text-muted"},[_vm._v(_vm._s(network.Name))])])])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }