<template>
  <div>
    <img src="/img/topup/background@2x.png" alt="Topups.com Lifestyle" class="page-bg w-100 img-fluid">
    <div class="container container-sm">
      <div class="card mb-4">
        <div class="card-body">
          <div class="text-center mb-3">
            <h1 class="card-title h5 font-weight-bold">
              Send mobile phone top-ups
            </h1>
            <h2 class="card-subtitle h6 font-weight-bold">
              Top-up any mobile network,
              <router-link
                :to="'/en-' + country.CountryIso.toLowerCase() + '/countries'"
                class="text-reset"
                >anywhere</router-link
              >!
            </h2>
          </div>
          <div class="form-group mb-1">
            <label for="country">Receiver's country:</label>
            <div class="input-group" v-if="country">
              <div class="input-group-prepend">
                <label class="input-group-text bg-transparent" for="country"
                  ><img
                    :src="
                      'https://imagerepo.ding.com/flag/' +
                        country.CountryIso +
                        '.png?height=16&compress=none'
                    "
                    :alt="country.CountryName"
                /></label>
              </div>
              <CountrySelect
                :items="countries"
                label="Your Location"
                :optgroup="country"
                id="country"
                @change="updateCountry($event[0])"
                class="border-left-0"
              />
            </div>
          </div>
        </div>
      </div>
    
      <!-- TrustBox widget - Micro Review Count -->
      <div
        v-if="$env != 'development'"
        id="trustbox"
        class="trustpilot-widget mb-3"
        data-locale="en-GB"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="5bf2cf3030ff6e00011c54f6"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
      >
        <a href="https://uk.trustpilot.com/review/topups.com" target="_blank"
          >Trustpilot</a
        >
      </div>
      <!-- End TrustBox widget -->

      <Networks v-if="country.CountryIso" class="mb-3" />
      <p class="text-center text-danger" v-else-if="!networks.length && !loading">
        Sorry we have no networks for your location
      </p>
    </div>

    <div class="bg-secondary mb-md-4 py-3">
      <div class="container container-sm">
        <h4 class="h5 text-center"><b>Stay Connected With Topups.com</b></h4>
        <p>
          We’re on a mission to change mobile top-ups online, making it the quick
          and easy option for staying in touch with friends &amp; family.
        </p>
        <p>
          The one-stop-shop for topping up, we'll send credit to an account or
          mobile phone top-up pin in less than 60 seconds.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Networks from "@/components/Networks.vue";
import CountrySelect from "@/components/Select.vue";

import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Networks,
    CountrySelect
  },
  data() {
    return {
      countries: [],
      networks: [],
      loading: false
    };
  },
  beforeMount() {
    this.locationData();
  },
  mounted() {
    if (this.$env != "development") {
      const trustbox = document.getElementById("trustbox");
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(trustbox);
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      order: "order",
      country: "country"
    })
  },
  methods: {
    locationData() {
      this.loading = true;
      this.$http({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}countries`
      }).then(
        result => {
          const networks = result.data.kyc.networks;

          if (networks && networks.length) {
            this.networks = networks;
          }

          this.countries = result.data.countries;
          
          if (this.country.CountryIso == null) {
            this.$store.dispatch("setCountry", result.data.kyc.country);
          }

          this.user.registration = {
            ip: result.data.kyc.ip,
            country: result.data.kyc.country.CountryName,
            status: "incomplete"
          };

          this.$store.dispatch("fetchUser", this.user);
        },
        error => {
          console.error(error);
        }
      );
    },
    updateCountry(country) {
      this.$store.dispatch("setCountry", country);
    }
  }
};
</script>
